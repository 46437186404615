
import { Options, Vue, Watch } from "vue-property-decorator";
import billTypePopupComponent from "./billTypePopupComponent.vue";
import checkTypePopupComponent from "./checkTypePopupComponent.vue";
import inputCheckBox from "./inputCheckBox.vue";
import inputprojectshow from "./inputprojectshow.vue";
import inputselectshow from "./inputselectshow.vue";
import inputSingleSelectshow from "./inputSingleSelectshow.vue";
import inputSpeechCheckBox from "./inputSpeechCheckBox.vue";
import limitDatePopupComponent from "./limitDatePopupComponent.vue";
import directorPopupComponent from "./directorPopupComponent.vue";
import * as api from "@/api/safecheck";
import File from "@/api/file/index.ts";

@Options({
  components: {
    billTypePopupComponent,
    checkTypePopupComponent,
    inputCheckBox,
    inputprojectshow,
    inputselectshow,
    inputSingleSelectshow,
    inputSpeechCheckBox,
    limitDatePopupComponent,
    directorPopupComponent,
  },
})
export default class extends Vue {
  private flag = false;
  private billIdscolumns = [
    { id: 1, name: "检查记录单" },
    { id: 2, name: "隐患整改单" },
    { id: 3, name: "停工整改单" },
  ];
  private checkIdscolumns = [
    { id: 2, name: "企业检查" },
    { id: 3, name: "项目检查" },
  ];
  private areaForm = {
    id: "",
    areaCode: "",
    areaName: "",
    areaItem: "",
  };
  private submitform: any = {
    sDeptItem: "",
    sArea: "",
    billType: "",
    checkType: "",
    targetDept: "",
    checkItem: "",
    billId: "",
    projectId: "",
    checkArea: "",
    speechCode: "",
    speechDept: "",
    speechItem: "",
    speechArea: "",
    checkUserName: "",
    inspectionList: [],
    checkUserId: "",
    limitDate: "",
    director: "",
  };

  private domainList = [];
  private projectIdscolumns = [];
  private checkDeptColumns = []; // 检查对象数组
  private checkItemColumns = []; // 检查项目数组
  private checkAreaColumns = []; // 检查项目数组
  private checkSpeechColumns = []; // 话术数组

  private uploadingNum = 0; //上传中的图片
  private uploadedNum = 0; //上传完成的图片

  protected billIdsonConfirm(val: any) {
    this.submitform.billType = val;
  }

  protected checkIdsonConfirm(val: any) {
    this.submitform.checkType = val;
  }

  protected inputCheckBox(val: any) {
    this.submitform.checkUserId = val;
  }

  protected areaChange() {
    (this as any).$refs.speechSel.fieldValue = "";
    (this as any).$refs.speechSel.result = [];
    api
      .checkInspectionList({
        inspectCode: this.submitform.sDeptItem + this.submitform.sArea,
      })
      .then((res: any) => {
        this.checkSpeechColumns = res.data;
      });
  }

  protected goBack() {
    this.$router.go(-1);
  }
  protected onSubmit() {
    this.domainList.map((item: any) => {
      let arr = "";
      item.value.map((val: any) => (arr += "," + val.id));
      this.submitform.inspectionList.push({
        inspectCode: item.inspectCode,
        inspectContent: item.inspectContent,
        value: arr.substr(1, arr.length - 1),
      });
    });
    api
      .mobileReportBillSave({
        projectId: this.$store.state.project.projectinfo.projectId,
        ...this.submitform,
      })
      .then((res: any) => {
        if (res.code == 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              this.$router.push("/app/safecheck/checkrecord");
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  @Watch("targetDept")
  onTargetDeptChanged(newValue: string, oldValue: string) {
    setTimeout(() => {
      api.checkAreaList({}).then((res: any) => {
        if (res.code === 0) {
          this.checkAreaColumns = res.data;
        }
      });
    }, 500);
  }

  mounted() {
    this.initprojectIds();
    this.initCheckObjects();
  }

  initprojectIds() {
    this.submitform.projectId = this.$store.state.project.projectinfo.projectId;
    api.projectlist().then((res: any) => {
      if (res.code === 0) {
        this.projectIdscolumns = res.data;
        this.projectIdscolumns = this.projectIdscolumns.filter((item: any) => {
          return (
            item.projectId === this.$store.state.project.projectinfo.projectId
          );
        });
      }
    });
  }
  initCheckObjects() {
    // 初始化检查相关的下拉属性
    // 检查对象
    api.checkDeptList().then((res: any) => {
      if (res.code === 0) {
        this.checkDeptColumns = res.data;
      }
    });
    // 检查项目
    api
      .checkItemList({
        itemDept: "",
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.checkItemColumns = res.data;
        }
      });
  }

  protected deptChange() {
    (this as any).$refs.itemSel.fieldValueArea = "";
    (this as any).$refs.speechSel.fieldValue = "";
    (this as any).$refs.speechSel.result = [];
  }

  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file) => {
      file.status = "uploading";
      file.message = "上传中...";
      this.uploadingNum++;
      this.flag = true;
      File.uploadFile("reportBill/", file.file.name, file.file, true).then(
        (res: any) => {
          file.status = "done";
          file.id = res.data.id;
          this.uploadedNum++;
          if (this.uploadingNum === this.uploadedNum) {
            this.flag = false;
          }
        }
      );
    });
  }
}
